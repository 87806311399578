import { Inter } from 'next/font/google'
import Head from "next/head"
import Image from 'next/image'
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { monthsSinceNovember2022 } from '../utils/utils';




const inter = Inter({ subsets: ['latin'] })

export default function Home() {

  const months = monthsSinceNovember2022() + 1;

  return (
<>
<Head>
  <title>UfukTemelEkmen</title>
</Head>
<main className="container mx-auto">
    <div className="flex flex-col md:flex-row">
        <div className="bg-slate-600 w-full md:w-1/4 rounded-lg overflow-hidden shadow-md hover:shadow-lg">
          <div className="p-4">
            <div  className="flex justify-center">
              <Image src="/mypic.jpg" alt="" width={200} height={200} className="rounded-full" />
            </div>
            <div className="flex justify-center font-semibold text-xl mb-2 mt-2 text-white">Ufuk Temel EKMEN</div>
            <div className="text-lg mb-2 mt-10 text-white">Kıdemli Teknik Analist & Sr. Oracle APEX ~ Sr. PL/SQL Developer</div>
            <div className="text-xs">
              <ul className="list-outside mt-3 text-white">
                <li>ORACLE</li>
                <li>PL/SQL</li>
                <li>ORACLE APEX</li>
                <li>HTML, CSS, JAVASCRIPT, JQUERY</li>
                <li>REACT/NEXT.JS</li>
              </ul>
            </div>
            <div className="text-white mt-8">
              <div className="text-lg">İletişim Bilgileri</div>
              <div className="text-sm mt-2">0537 738 02 57 (Work)</div>
              <div className="text-sm">ufuktemelekmen@gmail.com</div>
              <div className="flex gap-x-4 mt-6 p-0">
                <div className="w-8 h-8 hover:bg-white hover:text-blue-500 hover:rounded-full">
                  <a href="https://www.linkedin.com/in/temelekmen" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="w-8 h-8 p-0 hover:scale-110 transition-all"/>
                  </a>


                  
                </div>
                <a href="https://twitter.com/tmlkmn" target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="w-8 h-8 hover:text-blue-500 hover:scale-110 transition-all" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col bg-white w-full md:w-3/4 rounded-lg overflow-hidden shadow-md hover:shadow-lg">
          <div className="p-4 border-b-2">
            <div className="text-gray-700 text-base">
              <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                        <a href="https://www.kafein.com.tr/" target="_blank" rel="noopener noreferrer">
                          <Image src="/kafein logo.jpg" alt="" width={48} height={48} className="rounded-lg" />
                        </a>
                        <div className="flex-col ml-9">
                          <div className="font-bold">Kafein Yazılım</div>
                          <div className="font-semibold text-xs text-gray-500">Kas 2022 - Halen · {months} Ay (Remote)</div>
                        </div>
                  </div>
                  <div className="mt-2">
                      <ul className="list-disc text-sm ml-4">
                        <li>Yazılım sektöründe olan firmanın, dış kaynak olarak TÜRK TELEKOM yazılım geliştirme süreçlerinde rol alınması</li>
                        <li>TÜRK TELEKOM Envanter Yönetim Sistemlerine uygulanan sprint planlarına göre PL/SQL Developer görevinin üstlenilmesi</li>
                        <li>PACKAGE, PROCEDURE, FUNCTION, COLLECTIONS yapıları ile ileri seviyede PL/SQL ile geliştirme yapılması</li>
                        <li>Farklı sistemler arası bilgi akışı için WSDL, XSD vb. XML dosyalarının hazırlanması</li>
                        <li>Package/Metod vb. geliştirmelerin JAVA projelerine entegre edilmesi</li>
                        <li>Jira/Confluence gibi ortamlarda iş takibi ve agile/scrum metotları ile ekip çalışması</li>
                        <li>İletilen talepler için yapılan geliştirmelerin dökümante edilmesi</li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>

          <div className="p-4 border-b-2">
            <div className="text-gray-700 text-base">
              <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                        <a href="https://www.eddanetwork.com/" target="_blank" rel="noopener noreferrer">
                          <Image src="/edda logo.jpg" alt="" width={80} height={80} className="rounded-lg" />
                        </a>
                        <div className="flex-col">
                          <div className="font-bold ml-2">EddaNetwork</div>
                          <div className="font-semibold ml-2 text-xs text-gray-500">Ağu 2018 - Kas 2022 · 4 yıl 4 ay (Remote)</div>
                        </div>
                  </div>
                    
                  <div className="mt-1">
                      <ul className="list-disc text-sm ml-4">
                        <li>Yazılım sektöründe olan firmanın, analist ve yazılımcı olarak yazılımsal ERP projelerinin proje ortağı olarak hayata geçirilmesi </li>
                        <li>Müşteri ihtiyaçları baz alınarak iş gereksinimlerinin analiz edilmesi</li>
                        <li>Yapılan analizler sonucu oluşturulacak veritabanının ERD diyagramlarının çizilerek veritabanı mimarisinin oluşturulması </li>
                        <li>Uygulamanın yazılması</li>
                        <li>Kullanılan IDE; ORACLE APEX </li>
                        <li>Kullanılan diller; HTML, CSS, JAVASCRIPT, JQUERY, SQL, PL/SQL</li>
                        <li>Uygulamanın canlıya alınması ve kullanıcı eğitimlerinin verilmesi</li>
                        <li>Genel olarak ERP sisteminin; İnsan Kaynakları, Satış, Proje, Sipariş, Malzeme İhtiyaç Planlaması (MRP), Stok, Ürün, Ürün Konfigüratörü, Üretim, Sevkiyat, İdari işler, Bakım vb. tüm modüllerinin hayata geçirilmesi.</li>
                        <li>Ayrıca ERP sistemine ek olarak; Rezervasyon ve ekip atama sistemi, Dental iş takip sistemleri de yazılmıştır.</li>
                        <li>Jira/Confluence gibi ortamlarda iş takibi ve agile/scrum metotları ile ekip çalışması.</li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>

          <div className="p-4 border-b-2">
            <div className="flex flex-col text-gray-700 text-base">
                  <div className="flex flex-row items-center">
                        <a href="https://www.trimline.com.tr/tr/" target="_blank" rel="noopener noreferrer">
                          <Image src="/trimlogo.jpg" alt="" width={80} height={80} className="rounded-lg" />
                        </a>
                        <div className="flex-col">
                          <div className="font-bold ml-2">TRIMline Interiours</div>
                          <div className="font-semibold ml-2 text-xs text-gray-500">Şub 2016 - Eki 2017 · 1 yıl 9 ay (Tam Zamanlı)</div>
                        </div>
                  </div>
                    <div className="mt-1">
                        <ul className="list-disc text-sm ml-4">
                          <li>İç Mimari/Üretim sektöründe olan firmanın; fabrika üretim ortamı, proje yönetim ortamı ve tüm süreçlerinin analizleri</li>
                          <li>Şirket içi iş gereksinimlerinin toplanması ve uygun bir şekilde dökümante edilmesi</li>
                          <li>İş süreçlerinin iş akış diyagramlarının çizilmesi</li>
                          <li>Jira/Confluence gibi ortamlarda iş takibi ve agile/scrum metotları ile ekip çalışması.</li>
                          <li>Tamamlanan projeler; Uygulama ürün ağaçları ve MRP kurulumu, stok yönetimi, proje yönetimi, sipariş yönetimi vb. iş süreçlerinin yazılımsal olarak hayata geçirilmesi.</li>
                          <li>Oracle APEX ile uygulama geliştirme çalışmaları</li>
                          <li>İletilen talepler için yapılan geliştirmelerin dökümante edilmesi</li>
                        </ul>
                    </div>
            </div>
          </div>

          <div className="p-4">
            <div className="text-gray-700 text-base">
                  <div className="flex flex-col">
                    <div className="w-1/2 font-bold">
                    AYRICA
                    </div>
                  <div className="mt-2">
                      <ul className="list-disc text-sm ml-4">
                        <li>LINUX sistemlerle de basic düzeyde ORACLE veritabanı yönetimi gibi işlemler yapmaktayım. </li>
                        <li>UBUNTU 20.04 LTS işletim sistemli, kendi sanal sunucum üzerine kurduğum react/nextjs ~ mysql uygulamam üzerinde de çalışmalar yaparak öğrenme sürecindeyim.</li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </main>

</>
  )
}
